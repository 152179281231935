import React, { useEffect, useState } from 'react';
import Navbuttons from './Navbuttons';
import logo from '../img/Logo w_name white.png';

function Navbar(props) {
    const [activeButton, setActiveButton] = useState('navb1');

    // Function to handle button click
    const handleButtonClick = (id) => {
        setActiveButton(id);
    };
    useEffect(() => {
        const currentURL = window.location.href;
        if (currentURL.endsWith('/')) {
            setActiveButton('navb1');
        } else if (currentURL.endsWith('/products')) {
            setActiveButton('navb2');
        } else if (currentURL.endsWith('/events')) {
            setActiveButton('navb3');
        } else if (currentURL.endsWith('/galleries')) {
            setActiveButton('navb4');
        } else if (currentURL.endsWith('/contactus')) {
            setActiveButton('navb5');
        }
    }, []);

    return (
        <nav className="navbar disknav m-0 row sticky-top" style={{ zIndex: 50 }}>
            <div className="container-fluid">
                <div className="col-12 d-flex">
                    <div className="col-1">
                        <div className="col-12 d-flex justify-content-start">
                            <img src={logo} alt="logo" className="col-12 pt-1 logo" />
                        </div>
                    </div>
                    <div className="col-10">
                        <Navbuttons name="home" id="navb1" to="/" activeButton={activeButton} handleButtonClick={handleButtonClick} />
                        <Navbuttons name="products" id="navb2" to="/products" activeButton={activeButton} handleButtonClick={handleButtonClick} />
                        <Navbuttons name="events" id="navb3" to="/events" activeButton={activeButton} handleButtonClick={handleButtonClick} />
                        <Navbuttons name="galleries" id="navb4" to="/galleries" activeButton={activeButton} handleButtonClick={handleButtonClick} />
                        <Navbuttons name="contact us" id="navb5" to="/contact-us" activeButton={activeButton} handleButtonClick={handleButtonClick} />
                    </div>
                    <div className="col-1 d-flex justify-content-end">
                        <div className="col-10 d-flex justify-content-end p-2">
                            {/* Your user icon */}
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
