import facebook from "../img/icons8-facebook-48.png"
import instagram from "../img/icons8-instagram-logo-48.png"
import tiktok from "../img/icons8-tik-tok-48.png"
import whatsapp from "../img/icons8-whatsapp-48.png"
import logo from "../img/logo gold.png"
function Footer(props) {
    return (
        <>
            <div className="footer m-0 bg-black row p-lg-5 pb-lg-3 p-2">
                <div className={`col-lg-3 col-12 ${window.innerWidth < 1100 ? "hide" : ""}`}>
                    <p className="fs-lg-1 ps-3 fs-md-3 fs-5 text-light text-start footer-text">
                        Find a gallery
                    </p>
                    <p className="fs-lg-5 ps-3 fs-6 text-light text-start footer-text">
                        Our gallerys are available in the most famous malls and places, so you will reach them easily
                    </p>
                </div>
                <div className={` col-lg-3 col-12 ${window.innerWidth < 1100 ? " hide" : ""}`}>
                    <p className="fs-lg-1 ps-3 fs-md-3 fs-5 text-light text-center footer-text">
                        Follow us
                    </p>
                    <div className="row fs-lg-4 p-3 fs-6 text-light justify-content-center text-start pt-0 footer-text">
                        <div className="card2 col-8 bg-black rounded-3">
                            <span>Social</span>
                            <a className="social-link" href="https://www.facebook.com/Eyekemet" target="blank">
                                <img src={facebook} alt="facebook" className="col-5" />
                            </a>
                            <a className="social-link" href="https://www.instagram.com/eye.kemet?igsh=MXhzZHpidWNndTV5aA==" target="blank">
                                <img src={instagram} alt="instagram" className="col-5" />
                            </a>
                            <a className="social-link" href="https://www.tiktok.com/@eyekemet?_t=8m3Xr17W0KU&_r=1" target="blank">
                                <img src={tiktok} alt="tiktok" className="col-5" />
                            </a>
                            <a className="social-link" href="https://wa.me/201551479882" target="blank">
                                <img src={whatsapp} alt="whatsapp" className="col-5" />
                            </a>

                        </div>
                    </div>
                </div>
                <div className={`col-lg-3 col-12 ${window.innerWidth < 1100 ? "hide" : ""}`}>
                    <p className="fs-lg-1 ps-3 fs-md-3 fs-5 text-light text-start footer-text">
                        Contact us
                    </p>
                    <p className="fs-lg-4 ps-3 fs-6 text-light text-start footer-text">
                        +20 12 7455 0956
                        <br />
                        every day from 9 AM to 9 PM .
                    </p>
                </div>
                <div className="col-lg-3 col-12">
                    <img src={logo} alt="logo" className="col-12" />
                </div>
            </div>
            <div className="row m-0" style={{ backgroundColor: "#C4982A" }}>
                <p className="col-md-6 m-0 p-md-1 p-0 col-12 d-md-block d-none fs-lg-1 fs-md-3 fs-6 text-dark fw-bold text-center footer-text">
                    Copyright © EYE KEMET
                </p>
                <p className="col-md-6 m-0 p-md-1 col-12 fs-lg-1 fs-md-3 fs-6 text-dark fw-bold text-center footer-text">
                    Powered by MICHAEL KHALAF
                </p>
            </div>
        </>
    )
}
export default Footer;