import React, { useState } from 'react'; import Navbuttons from "./Navbuttons";
import logo from "../img/Logo w_name white.png";
import menu from "../img/icons8-menu-32.png";
function Phonenav(props) {
    const [activeButton, setActiveButton] = useState('navb1');

    // Function to handle button click
    const handleButtonClick = (id) => {
        setActiveButton(id);
    };

    return (
        <nav className="navbar col-12 bg-black sticky-top" style={{ zIndex: 50 }}>
            <div className="container-fluid">
                <div className='row'>
                    <div className="navbar-brand col-8 d-flex justify-content-start">
                        <img src={logo} alt="logo" className="col-6 logo" />
                    </div>
                    <button className="col-3 navbar-toggler border-0 border" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                        <img src={menu} alt="menu" />
                    </button>
                </div>
                <div className="offcanvas offcanvas-end bg-black" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                    <div className="offcanvas-header">
                        <button type="button" className="btn-close bg-secondary" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body d-flex flex-column ">
                        <Navbuttons name="home" id="navb1" to="/" activeButton={activeButton} handleButtonClick={handleButtonClick} />
                        <Navbuttons name="products" id="navb2" to="/products" activeButton={activeButton} handleButtonClick={handleButtonClick} />
                        <Navbuttons name="events" id="navb3" to="/events" activeButton={activeButton} handleButtonClick={handleButtonClick} />
                        <Navbuttons name="galleries" id="navb4" to="/galleries" activeButton={activeButton} handleButtonClick={handleButtonClick} />
                        <Navbuttons name="contact us" id="navb5" to="/contact-us" activeButton={activeButton} handleButtonClick={handleButtonClick} />
                    </div>
                </div>
            </div>
        </nav >
    );
}
export default Phonenav;