import React from 'react';

function Loader() {
    return (
        <div className='container-fluid p-5 d-flex bg-black justify-content-center align-items-center' style={{ position: 'fixed', top: '0', left: '0', height: '100vh', width: '100vw', zIndex: 100 }}>
            <div className="eye-lid">
                <div className="eye">
                    <div className="cornea">
                        <div className="white-pupil"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Loader;
