import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Loader from './componenets/Loader';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'jquery/dist/jquery.min.js';
import 'jquery/dist/jquery.js';
import 'aos/dist/aos.css';
import 'aos/dist/aos.js';
import 'font-awesome/css/font-awesome.min.css';
import 'font-awesome/css/font-awesome.css';
import Aos from 'aos';
import Background from './componenets/Background';
import Navbar from './componenets/Navbar';
import Phonenav from './componenets/Phonenav';
import Footer from './componenets/Footer';
import { BrowserRouter } from 'react-router-dom';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
Aos.init();

function handleTouchStart(event) {
  // Your touchstart event handling logic goes here
}
window.addEventListener('touchstart', handleTouchStart, { passive: true });

// Define your main component loader
function MainLoader() {
  const [isAppReady, setIsAppReady] = useState(false);

  useEffect(() => {
    // Simulate an async operation (e.g., loading data, fetching API)
    setTimeout(() => {
      setIsAppReady(true); // Set the App as ready after some time
    }, 10000); // Simulate 10 seconds of loading time
  }, []);

  return (
    <>
      {!isAppReady ? (
        <Loader /> // Show Loader if App is not ready
      ) : null}
      <BrowserRouter>
        <Background />
        <div className="App row  bg-transparent m-0 p-0">
          <div className="row app_body bg-black  bg-opacity-50 col-12 p-0 m-0">
            <div className="bg-dark p-0 container-fluid bg-opacity-50">
              {window.innerWidth > 1100 ? <Navbar /> : <Phonenav />}
              <App />
              <Footer />
            </div>
          </div>
        </div>
      </BrowserRouter>
    </>
  );
}

root.render(<MainLoader />);
