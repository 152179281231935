import React, { useEffect, lazy, Suspense } from 'react';
import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import Loader from './componenets/Loader';

const Home = lazy(() => import('./componenets/Home'));
const Products = lazy(() => import('./componenets/Products'));
const Events = lazy(() => import('./componenets/Events'));
const Galleries = lazy(() => import('./componenets/Galleries'));
const ContactUs = lazy(() => import('./componenets/ContacUs'));

const HomeURL = "/";

function App() {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top of the page when the route changes
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    // Add event listener when component mounts
    const beforeUnloadListener = (e) => {
      // Display an alert when the user tries to reload the page
      e.preventDefault();
      e.returnValue = '';
      alert('When you reload this page, you will lose your data.');
      // Redirect to the home page
      window.location.href = HomeURL;
    };

    window.addEventListener('beforeunload', beforeUnloadListener);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadListener);
    };
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path={HomeURL} element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/galleries" element={<Galleries />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/events" element={<Events />} />
      </Routes>
    </Suspense>
  );
}

export default App;
