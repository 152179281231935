import React from 'react';
import { Link } from 'react-router-dom';

function Navbuttons(props) {
    const { id, to, name, activeButton, handleButtonClick } = props;

    const handleClick = () => {
        handleButtonClick(id);
    };

    return (
        <button
            className={`cta px-3 me-2 m-1 py-2 ${activeButton === id ? 'active' : ''}`}
            onClick={handleClick}
            id={id}
        >
            <Link to={to} className="text-decoration-none">
                <span className="hover-underline-animation py-2 text-center">
                    {name}
                </span>
            </Link>
        </button>
    );
}

export default Navbuttons;
